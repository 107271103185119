import {Size} from './size';
import {EventEmitter} from '@angular/core';
import {Shape} from './shape';
import {SizeService} from "../services/size.service";
import {Subscription} from "rxjs";

export class Opening {

  public change: EventEmitter<Opening> = new EventEmitter<Opening>();
  private sizeChangeRef: Subscription;

  _size: Size;
  get size() {
    return this._size;
  }

  set size(value: Size) {
    if (this.sizeChangeRef && !this.sizeChangeRef.closed) {
      this.sizeChangeRef.unsubscribe();
    }

    this._size = value;

    this.sizeChangeRef = this._size.change.subscribe(() => {
      this.change.emit(this);
    });
  }

  get bottom(): number {
    return this._size.height + this.top;
  }

  _shape: Array<Shape>;
  fill = 'none';
  left: number;
  top: number;
  offset: number;
  rotation = 0;
  reveal = 0;
  vGrooveOffset = 0;
  restrictions: any = null;
  content = '';

  get urlString(): string {
    if (!isNaN(this.left) && this.left !== 0 && typeof !isNaN(this.top) && this.top !== 0) {
      return this.size.urlString + 'x' + this.left + 'x' + this.top + 'x' + this.rotation + 'x' + this.reveal + 'x' +
        this.vGrooveOffset + ':' + this.shapeUrlString + ':' + this.fill;
    } else if (typeof this.offset !== 'undefined') {
      return this.size.urlString + 'x' + this.offset + ':' + this.shapeUrlString + ':' + this.fill;
    } else {
      return this.size.urlString + ':' + this.shapeUrlString + ':' + this.fill;
    }
  }

  toString(useFractions = true, includeInches = true, includeSpaces = true) {
    if (!isNaN(this.left) && this.left !== 0 && typeof !isNaN(this.top) && this.top !== 0) {
      const inchStr = includeInches ? '"' : '';
      const separatorStr = includeSpaces ? ' x ' : 'x';
      const left = useFractions ? this.sizeService.fractionAsString(this.left) : this.left;
      const top = useFractions ? this.sizeService.fractionAsString(this.top) : this.top;
      return `${this.size.toString()}${separatorStr}${left}${inchStr}${separatorStr}${top}${inchStr}`;
    } else {
      return this.size.toString();
    }
  }

  toNumericString() {
    return this.toString(false, false, false);
  }

  private sizeService: SizeService = new SizeService();

  constructor(size: Size, shape: Array<Shape>) {
    this.size = size;
    this.shape = shape;
  }

  toJson(): any {
    return {
      size: this.size.toJson(),
      shape: this.shape,
      left: this.left,
      top: this.top,
      offset: this.offset,
      rotation: this.rotation,
      reveal: this.reveal,
      vGrooveOffset: this.vGrooveOffset,
      restrictions: this.restrictions,
      print: this.print
    };
  }

  static get INDETERMINATE(): Opening {
    return new Opening(new Size(NaN, NaN), [Shape.DEFAULT]);
  }

  get isIndeterminate(): boolean {
    return this.size && this.size.isIndeterminate && this.shape && this.shape.length === 1 && this.shape[0].code === Shape.DEFAULT.code;
  }
  get shape() {
    return this._shape;
  }

  set shape(value: Array<Shape>) {
    this._shape = value;
    this.change.emit(this);
  }

  get shapeUrlString(): string {
    return this._shape.map((shapeData: any) => {
      return Shape.fromJson(shapeData).toUrlString();
    }).join('-');
  }

  private _print: any;
  public get print(): any { return this._print; }
  public set print(val: any) {
    this._print = val;
    this.change.emit(this);
  }

  public destroy() {
    if (this.sizeChangeRef && !this.sizeChangeRef.closed) {
      this.sizeChangeRef.unsubscribe();
    }
  }
}

export class OpeningWithPosition extends Opening {
  position: any;
  vGroovePosition: any;

  constructor(_size: Size, _shape = [Shape.DEFAULT]) {
    super(_size, _shape);
  }
}
