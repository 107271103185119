import {SizeService} from '../services/size.service';
import {EventEmitter} from '@angular/core';
import {SizeRange} from './sizeRange';
import {environment} from '../../../environments/environment';

export class Size {

  public prop: string = 'test';

  private _width:number = 1;
  get width() { return this._width }
  set width(value:number) { this._width = value; this.update();}

  private _widthRange:SizeRange;
  get widthRange():SizeRange {
    return this._widthRange;
  }
  set widthRange(value:SizeRange) {
    this._widthRange = value;
  }

  public get orientation(): string {
    return this.width > this.height ? 'landscape' : 'portrait';
  }

  private _height:any = 1;
  get height() { return this._height }
  set height(value:number) {
    this._height = value;
    this.update();
  }

  private _heightRange:SizeRange;
  get heightRange():SizeRange {
    return this._heightRange;
  }
  set heightRange(value:SizeRange) {
    this._heightRange = value;
  }

  public get ui():number {
    return this.width + this.height;
  }

  sizeService: SizeService = new SizeService();

  constructor(width:number, height: number) {
    this._width = width;
    this._height = height;
  }

  public rotate():void {
    let w = this._width;
    this._width = this._height;
    this._height = w;
    this.update();
  }

  public change:EventEmitter<Size> = new EventEmitter<Size>();

  get urlString() {
    return this.width + 'x' + this.height;
  }

  get incomplete():boolean {
    return !this.width || !this.height;
  }

  get partiallyComplete():boolean {
    return this.incomplete && (this.width || this.height) ? true : false;
  }

  toString(useFractions = true, includeInches = true, includeSpaces = true) {
    const inchStr = includeInches ? '"' : '';
    const separatorStr = includeSpaces ? ' x ' : 'x';
    const width = useFractions ? this.sizeService.fractionAsString(this.width) : this.width;
    const height = useFractions ? this.sizeService.fractionAsString(this.height) : this.height;
    return `${width}${inchStr}${separatorStr}${height}${inchStr}`;
  }

  toNumericString() {
    return this.toString(false,false,false);
  }

  toJson(): any {
    return {
      width: this.width,
      height: this.height
    };
  }

  deferredUpdate:any;
  update() {
    if (this.deferredUpdate) {
      clearTimeout(this.deferredUpdate);
    }

    this.deferredUpdate = setTimeout(() => {
      this.change.emit(this);
    });
  }

  updateRange(limiter:Size, limitType:string, offset:number):void {
    switch (limitType) {
      case 'outer': {
        let wLimit = limiter && !isNaN(limiter.width) ? limiter.width : environment.sizeLimits.outer.secondaryMax;
        let hLimit = limiter && !isNaN(limiter.height) ? limiter.height : environment.sizeLimits.outer.max;
        let margin = wLimit + hLimit < environment.minMargins.ui ? environment.minMargins.minUnder + offset : environment.minMargins.minOver + offset;
        this.widthRange = new SizeRange(environment.sizeLimits.opening.min, wLimit - margin * 2);
        this.heightRange = new SizeRange(environment.sizeLimits.opening.min, hLimit - margin * 2);
        break;
      }
      case 'opening': {
        let wLimit = limiter && !isNaN(limiter.width) ? limiter.width : 0;
        let hLimit = limiter && !isNaN(limiter.height) ? limiter.height : 0;
        let margin = wLimit + hLimit + 2 < environment.minMargins.ui ? environment.minMargins.minUnder + offset: environment.minMargins.minOver + offset;
        let widthMax = Math.max(this.height > environment.sizeLimits.outer.secondaryMax ? environment.sizeLimits.outer.secondaryMax : environment.sizeLimits.outer.max)
        let heightMax = Math.max(this.width > environment.sizeLimits.outer.secondaryMax ? environment.sizeLimits.outer.secondaryMax : environment.sizeLimits.outer.max)
        this.widthRange = new SizeRange(Math.max(wLimit + margin * 2, environment.sizeLimits.outer.min), widthMax);
        this.heightRange = new SizeRange(Math.max(hLimit + margin * 2, environment.sizeLimits.outer.min), heightMax);
        break;
      }
    }
  }

  equals(size: Size): boolean {
    return this.width === size.width && this.height === size.height;
  }

  get isIndeterminate(): boolean
  {
    return isNaN(this.width) && isNaN(this.height);
  }

  get longerDimension(): number {
    return Math.max(this.width, this.height);
  }

  get shorterDimension(): number {
    return Math.min(this.width, this.height);
  }

  public destroy() {
    if (this.change && !this.change.closed) {
      this.change.unsubscribe();
    }
  }
}
