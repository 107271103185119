<!-- footer -->
<footer>
  <div class="fx-layout-column fx-layout-row--gt-xs fx-align--x-center fx-gap--0px">

    <div class="fx-flex--50">
      <img loading="lazy" src="assets/images/logo-white.png" alt="Matboard and More Logo">

      <p>
        We sell pre cut mats and custom picture frames in any size,
        color and quantity. Our products also include custom matting,
        backing boards, clear bags, show kits, matboard value packs,
        custom plexiglass.
      </p>
    </div>

    <div class="fx-flex--50 fx-layout-column footer-nav">
      <mat-nav-list class="fx-layout-row fx-layout-wrap">
        @for (menuItem of menuItems; track menuItem) {
          <mat-list-item class="fx-flex--50--lt-lg fx-flex--33--gt-md fx-flex-ext-no-grow">
            <a [routerLink]="menuItem.url">{{ menuItem.title }}</a>
          </mat-list-item>
        }

        <!--
        Tos, privacy policy, faq, service polcily
        -->
      </mat-nav-list>

      <div class="fx-layout-row fx-align--x-center fx-align--start-y" class="social">

        <a class="fx-flex--50 review-summary" *ngIf="tpReviewSummary" href="https://www.trustpilot.com/review/matboardandmore.com">
          <img lazyLoad="/assets/images/trustpilot.png" alt="TrustPilot Logo">
          <div>
            <strong>{{ tpReviewSummary.rating }}/5</strong> trust score<br/>based on
            <strong>{{ tpReviewSummary.reviewCount }}</strong> reviews
          </div>
        </a>
        <div class="fx-flex--50 review-summary" *ngIf="rrReviewSummary">
          <h4>CUSTOMER REVIEWS</h4>
          <div>
            <strong>{{ rrReviewSummary.rating }}/5</strong> score<br/>based on
            <strong>{{ rrReviewSummary.reviewCount }}</strong> reviews
          </div>
          <a
            class="reviews-logo"
            href="https://www.resellerratings.com"
            onclick="window.open('https://seals.resellerratings.com/landing.php?seller=98973','name','height=760,width=780,scrollbars=1');return false;">
            <img
              style='border:none;'
              xsrc='//seals.resellerratings.com/seal.php?seller=98973'
              src="/assets/images/reseller-ratings-elite.png"
              alt="Reseller Ratings Elite Business"
              oncontextmenu="alert('Copying Prohibited by Law'); return false;"
            />
          </a>
        </div>

        <div class="fx-flex--50 follow">
          <h4 class="upper">FOLLOW US</h4>

          <button mat-icon-button color="tertiary">
            <a href="https://www.pinterest.com/matboardandmore" target="_blank">
              <mat-icon svgIcon="pinterest"> </mat-icon>
            </a>
          </button>
          <button mat-icon-button color="tertiary">
            <a href="https://x.com/matboardandmore" target="_blank">
              <mat-icon svgIcon="x-social"> </mat-icon>
            </a>
          </button>
          <button mat-icon-button color="tertiary">
            <a href="https://www.facebook.com/MatboardandMore" target="_blank">
              <mat-icon svgIcon="facebook-box"> </mat-icon>
            </a>
          </button>
          <button mat-icon-button color="tertiary">
            <a href="https://www.instagram.com/MatboardandMore" target="_blank">
              <mat-icon svgIcon="instagram"> </mat-icon>
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <small>
      © 2012 - {{ currentYear}} Matboard and More LLC, All Rights Reserved
    </small>
  </div>
</footer>
