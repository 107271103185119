<ng-container *ngIf="view === 'sign in'">
  <div mat-dialog-title class="fx-layout-row fx-align--x-center">
    <h3>Sign In</h3>
    <div class="fx-flex-grow"></div>
    <button mat-icon-button mat-dialog-close *ngIf="configs.closeMethod == 'x'" tabindex="-1">
      <mat-icon svgIcon="x"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <form name="signIn">
      <mat-form-field>
        <mat-label>email address</mat-label>
        <input name="email" matInput type="email" [(ngModel)]="signInForm.email">
      </mat-form-field>

      <mat-form-field>
        <mat-label>password</mat-label>
        <mbnm-password-revealer>
          <input name="password" matInput type="password" [(ngModel)]="signInForm.password">
        </mbnm-password-revealer>
      </mat-form-field>
    </form>
    @if (popUpMessage) {
      <p class="pop-up-message" [innerHTML]="popUpMessage"></p>
    }
  </mat-dialog-content>

  <mat-dialog-actions class="fx-layout-column">
    <div class="fx-layout-row fx-align--space-between-x">
      <button (click)="signIn()" mat-flat-button color="primary">Sign In</button>
      <button (click)="cancelSignIn()" *ngIf="!dialogRef.disableClose" mat-flat-button color="secondary">Cancel</button>
    </div>
    <div class="fx-layout-row fx-align--space-between-x">
      <a href="javascript:void(0);" (click)="resetPassword()">Forgot Password</a>
      <a href="javascript:void(0);" (click)="view='register'">Register</a>
    </div>
  </mat-dialog-actions>

</ng-container>

<ng-container *ngIf="view === 'register'">
  <div mat-dialog-title class="fx-layout-row fx-align--x-center">
    <h3>Register</h3>
    <div class="fx-flex-grow"></div>
    <button mat-icon-button mat-dialog-close *ngIf="configs.closeMethod == 'x'" tabindex="-1">
      <mat-icon svgIcon="x"></mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <form>
      <mat-form-field>
        <mat-label>full name</mat-label>
        <input matInput
               name="fullname"
               required
               [(ngModel)]="registerForm.fullname">
      </mat-form-field>
      <mat-form-field>
        <mat-label>phone number</mat-label>
        <input matInput
               name="phone"
               minlength="10"
               required
               [(ngModel)]="registerForm.phonenumber">
      </mat-form-field>
      <mat-form-field>
        <mat-label>email address</mat-label>
        <input matInput
               name="email"
               type="email"
               required
               [(ngModel)]="registerForm.email">
      </mat-form-field>
      <mat-checkbox *ngIf="page === 'checkout'" name="registerFlag" [(ngModel)]="createAccount">Register for an account</mat-checkbox>
      <mat-form-field *ngIf="createAccount || page !== 'checkout'">
        <mat-label>password</mat-label>
        <mbnm-password-revealer>
          <input matInput
                 type="password"
                 name="password"
                 minlength="6"
                 required
                 [(ngModel)]="registerForm.password">
        </mbnm-password-revealer>
      </mat-form-field>
      <mat-form-field *ngIf="createAccount || page !== 'checkout'">
        <mat-label>confirm password</mat-label>
        <mbnm-password-revealer>
          <input matInput
                 type="password"
                 name="confirmPassword"
                 minlength="6"
                 required
                 [(ngModel)]="registerForm.confirmPassword">
        </mbnm-password-revealer>
      </mat-form-field>
      <mat-checkbox name="newsLetter" value="SUBSCRIBE_REQUESTED"
                    (ngModelChange)="registerForm.subscriptions.newsletterEmailStatus = $event ? 'SUBSCRIBE_REQUESTED' : null"
                    [ngModel]="registerForm.subscriptions.newsletterEmailStatus">
        Email me news & promotions
      </mat-checkbox>
      <!--
      <mat-checkbox name="smsMarketing"
                    [ngModel]="registerForm.subscriptions.smsMarketingStatus"
                    (ngModelChange)="showSMSDisclaimer($event)">
        Text me news & promotions
      </mat-checkbox>
      -->
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="fx-layout-column">
    <div class="fx-layout-row fx-align--space-between-x">
      <button [ngClass]="{'full-width': dialogRef.disableClose}" mat-flat-button color="primary" (click)="register()">
        <ng-container *ngIf="createAccount">Register</ng-container>
        <ng-container *ngIf="!createAccount">Checkout as Guest</ng-container>
      </button>
      <button (click)="cancelSignIn()" mat-flat-button color="secondary" *ngIf="!dialogRef.disableClose">Cancel</button>
    </div>
    <div class="fx-layout-row fx-align--space-between-x">
      <a href="javascript:void(0);" (click)="resetPassword()">Forgot Password</a>
      <a href="javascript:void(0);" (click)="view='sign in'">Sign In</a>
    </div>
  </mat-dialog-actions>
</ng-container>


<ng-container *ngIf="view === 'update password'">
  <div mat-dialog-title class="fx-layout-row fx-align--x-center">
    <h3 *ngIf="user?.isGuest" >Add Password to Register</h3>
    <h3 *ngIf="!user?.isGuest" >Account Security</h3>
    <div class="fx-flex-grow"></div>
    <button mat-icon-button mat-dialog-close *ngIf="configs.closeMethod == 'x'" tabindex="-1">
      <mat-icon svgIcon="x"></mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <form class="fx-layout-column"
          #passform [formGroup]="passwordFormGroup"
          (submit)="savePasswordChange()">
      <mat-form-field *ngIf="!user?.isGuest">
        <mat-label>old password</mat-label>
        <mbnm-password-revealer>
          <input matInput
                 type="password"
                 required
                 minlength="6"
                 formControlName="old">
        </mbnm-password-revealer>
      </mat-form-field>
      <mat-form-field>
        <mat-label>new password</mat-label>
        <mbnm-password-revealer>
          <input matInput
                 type="password"
                 required
                 minlength="6"
                 formControlName="new">
        </mbnm-password-revealer>
      </mat-form-field>
      <mat-form-field>
        <mat-label>confirm password</mat-label>
        <mbnm-password-revealer>
          <input matInput
                 type="password"
                 required
                 minlength="6"
                 formControlName="confirm">
        </mbnm-password-revealer>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="fx-layout-row fx-align--space-between-x">
    <ng-container *ngIf="!user.isGuest">
      <button type="submit" mat-flat-button color="primary">Update Password</button>
      <button type="button" mat-flat-button color="secondary-subtle" (click)="resetPasswordForm()">Cancel</button>
    </ng-container>

    <ng-container *ngIf="user.isGuest">
      <button type="submit" mat-flat-button color="primary">Create Account</button>
    </ng-container>
  </mat-dialog-actions>
</ng-container>
